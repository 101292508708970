import React from 'react';
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { Section, SectionOverlap, TwoColumns } from 'src/components/Section';
import { LearningAndTechno } from 'src/components/Bootcamp/index';
import { RecruitersSection } from 'src/components/Partners';
import { AnchorButton } from 'src/components/Button';
import { DescriptionBlock } from 'src/components/DescriptionBlock';
import { StaticImage } from 'gatsby-plugin-image';
import { ProjectsExamples } from 'src/components/Projects';

/* --- import Style ----*/
import { mediaQueries } from 'src/style/breakpoints';
import { BtnWrapper, HeroWrapper, HeroLeft } from 'src/components/Hero/elements';
import {
  H2,
  HeaderTitle,
  HeaderDescription,
  TextContainer,
  SectionTitle,
} from 'src/components/Typography';
import sizes from 'src/style/sizes';
import { CTAContainer, CTAInfoContent } from 'src/components/CTA/elements';
import { StarsCTAFooter } from 'src/components/Illustrations';
import { RocketIllustration } from 'src/components/Illustrations/RocketIllustration';

const Recruiters = ({ location }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2B"
      pageName="Recrutement étudiants"
      pageType="form"
      metaTitle="Recrutez nos étudiants à la sortie de la formation | Le Reacteur"
      metaDescription="Les étudiants du Reacteur sont formés à la programmation Web et Mobile. Nous leur enseignons le JavaScript, HTML/CSS, Node.js, React et React Native. Déposez vos offres de recrutement en ligne !"
    >
      <TopbarStaticSpace />
      {/* <HeroBG80vh> */}
      <Section theme="primary" hero={true} margin>
        <HeroWrapper>
          <HeroLeft css={{ flex: 1 }}>
            <HeaderTitle>Embauchez votre prochain talent tech</HeaderTitle>
            <HeaderDescription
              css={{
                marginTop: 30,
                textAlign: 'center',
                [mediaQueries.desktopUp]: {
                  marginRight: 60,
                  textAlign: 'left',
                },
              }}
            >
              Le Reacteur a créé le premier bootcamp Web et Mobile d'Europe. Imaginée par Xavier
              Colombel et Farid Safi, cette formation a pour but de faire de ses étudiants, de
              véritables développeurs juniors, en un temps record.
            </HeaderDescription>
            <BtnWrapper>
              <AnchorButton
                to="/offre-d-emploi/"
                css={{
                  marginRight: 20,
                  [mediaQueries.phoneOnly]: {
                    marginBottom: '20px',
                    width: '100%',
                    marginRight: 0,
                  },
                }}
              >
                <H2>Déposer une offre d'emploi</H2>
              </AnchorButton>

              <AnchorButton
                secondary
                to="/contact/"
                css={{
                  [mediaQueries.phoneOnly]: {
                    marginBottom: '20px',
                    width: '100%',
                  },
                }}
              >
                Nous contacter
              </AnchorButton>
            </BtnWrapper>
          </HeroLeft>
        </HeroWrapper>
      </Section>
      {/* </HeroBG80vh> */}

      <SectionOverlap marginTop={-100} theme="secondary">
        <SectionTitle css={{ padding: '60px 0px 60px 0px' }}>
          Pourquoi choisir nos étudiants ?
        </SectionTitle>
        <TwoColumns
          sizeLeft={sizes.columnWidth.medium}
          left={
            <div>
              <DescriptionBlock title="Ils ont reçu un enseignement focus sur JavaScript">
                Au Reacteur, nous les avons formés sur une des stacks les plus demandées par les
                startups, à savoir : HTML, CSS, JavaScript, Node.js, Express, MongoDB, React, React
                Native, etc.
              </DescriptionBlock>
              <StaticImage
                src="../../src/data/images/2023_students8.jpg"
                alt="Recruter les élèves du Reacteur"
                // placeholder="none"
                css={{
                  flex: 1,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 5,
                  marginTop: 20,
                  boxShadow: '5px 20px 40px rgba(0, 0, 0, 0.2)',
                  [mediaQueries.phoneOnly]: {
                    height: '70%',
                  },
                  [mediaQueries.tabletPortraitUp]: {
                    height: '60%',
                  },
                  [mediaQueries.tabletLandscapeUp]: {
                    height: 340,
                  },
                }}
              />
            </div>
          }
          sizeRight={sizes.columnWidth.medium}
          right={
            <div>
              <div>
                <DescriptionBlock title="Ils sont curieux, travailleurs et volontaires">
                  Nos étudiants ont tous été triés sur le volet, en fonction de leurs capacités à
                  travailler mais aussi et surtout par rapport à leur motivation. Notre bootcamp
                  demande énormément de travail et d'investissement personnel. Embaucher un de nos
                  élèves, c'est l'assurance de faire travailler quelqu'un de passionné et de
                  conciencieux. Ils ont tous <u>appris à apprendre</u>, à débloquer des problèmes
                  par eux-mêmes, à gagner en autonomie. Certains ont tout quitté pour changer de
                  métier, pas par obligation mais parce que leur soif d'apprendre à coder était plus
                  forte que tout.
                </DescriptionBlock>
              </div>

              <DescriptionBlock title="Il ont déjà travaillé, pour la plupart, sur un projet réel">
                En fin de formation, nous faisons travailler nos étudiants sur des projets de MVP,
                pour de vraies startups. En groupe de 3 à 5 personnes, ils réalisent un site Web ou
                une application Mobile et mettent en pratique ce que nous leur avons enseigné.
              </DescriptionBlock>
            </div>
          }
        />
      </SectionOverlap>

      <LearningAndTechno title="Voici ce que nos étudiants apprennent en formation" />

      <ProjectsExamples theme="secondary" recruitersSection={true} />

      <RecruitersSection title="Ils ont recruté nos élèves" theme="light" />

      <Section theme="primary">
        <CTAContainer>
          <RocketIllustration />
          <StarsCTAFooter />
          <CTAInfoContent>
            <SectionTitle isCenter={true}>
              Et si vous recrutiez <br /> un de nos élèves ?
            </SectionTitle>
            <div
              css={{
                padding: '0px 0px 40px 0px',
                maxWidth: 600,
                margin: '0 auto',
              }}
            >
              <TextContainer>
                Vous recherchez un développeur fullstack, un développeur front-end, ou un
                développeur back-end ? Commencez par remplir notre formulaire de contact et nous
                vous contacterons.
              </TextContainer>
            </div>

            <AnchorButton to="/offre-d-emploi/">Déposer une offre d'emploi</AnchorButton>
          </CTAInfoContent>
        </CTAContainer>
      </Section>
    </Layout>
  );
};
export default Recruiters;

// export const query = graphql`
//   query {
//     image: file(relativePath: { eq: "image17.jpg" }) {
//       childImageSharp {
//         gatsbyImageData(quality: 80, width: 1300)
//       }
//     }
//   }
// `;
